exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adopters-tsx": () => import("./../../../src/pages/adopters.tsx" /* webpackChunkName: "component---src-pages-adopters-tsx" */),
  "component---src-pages-aqavit-tsx": () => import("./../../../src/pages/aqavit.tsx" /* webpackChunkName: "component---src-pages-aqavit-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-temurin-archive-tsx": () => import("./../../../src/pages/temurin/archive.tsx" /* webpackChunkName: "component---src-pages-temurin-archive-tsx" */),
  "component---src-pages-temurin-index-tsx": () => import("./../../../src/pages/temurin/index.tsx" /* webpackChunkName: "component---src-pages-temurin-index-tsx" */),
  "component---src-pages-temurin-nightly-tsx": () => import("./../../../src/pages/temurin/nightly.tsx" /* webpackChunkName: "component---src-pages-temurin-nightly-tsx" */),
  "component---src-pages-temurin-release-notes-tsx": () => import("./../../../src/pages/temurin/release-notes.tsx" /* webpackChunkName: "component---src-pages-temurin-release-notes-tsx" */),
  "component---src-pages-temurin-releases-tsx": () => import("./../../../src/pages/temurin/releases.tsx" /* webpackChunkName: "component---src-pages-temurin-releases-tsx" */),
  "component---src-templates-asciidoc-template-tsx": () => import("./../../../src/templates/asciidocTemplate.tsx" /* webpackChunkName: "component---src-templates-asciidoc-template-tsx" */),
  "component---src-templates-author-page-tsx": () => import("./../../../src/templates/authorPage.tsx" /* webpackChunkName: "component---src-templates-author-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-month-after-eclipsecon-adoptium-community-day-summary-and-more-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/a-month-after-eclipsecon-adoptium-community-day-summary-and-more/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-month-after-eclipsecon-adoptium-community-day-summary-and-more-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-short-exploration-of-java-class-pre-initialization-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/a-short-exploration-of-java-class-pre-initialization/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-short-exploration-of-java-class-pre-initialization-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-summary-of-the-july-2022-retrospectives-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/a-summary-of-the-july-2022-retrospectives/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-summary-of-the-july-2022-retrospectives-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-automated-deployment-of-nagios-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-automated-deployment-of-nagios/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-automated-deployment-of-nagios-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-celebrates-first-release-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-celebrates-first-release/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-celebrates-first-release-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-infrastructure-management-with-nagios-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-infrastructure-management-with-nagios/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-infrastructure-management-with-nagios-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-reproducible-builds-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-reproducible-builds/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-reproducible-builds-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-reproducible-verification-builds-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-reproducible-verification-builds/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-reproducible-verification-builds-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-to-promote-broad-range-of-compatible-open-jdk-builds-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/Adoptium-to-promote-broad-range-of-compatible-OpenJDK-builds/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-to-promote-broad-range-of-compatible-open-jdk-builds-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-welcomes-google-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-welcomes-google/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-welcomes-google-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-welcomes-rivos-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptium-welcomes-rivos/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptium-welcomes-rivos-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptopenjdk-jfrog-io-has-been-deprecated-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/adoptopenjdk-jfrog-io-has-been-deprecated/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-adoptopenjdk-jfrog-io-has-been-deprecated-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-aqavit-graduation-ceremony-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/aqavit-graduation-ceremony/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-aqavit-graduation-ceremony-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-aqavit-scope-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/aqavit-scope/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-aqavit-scope-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-availability-of-jdk-8-u-352-b-05-ea-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/availability-of-jdk8u352-b05-ea/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-availability-of-jdk-8-u-352-b-05-ea-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-early-access-builds-feb-2024-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/early-access-builds-feb2024/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-early-access-builds-feb-2024-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-early-access-builds-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/early-access-builds/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-early-access-builds-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-adoptium-announcement-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-adoptium-announcement/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-adoptium-announcement-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-11020-1-and-1708-1-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-11020.1-and-1708.1/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-11020-1-and-1708-1-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-17-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-17-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-17-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-19-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-19-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-19-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-21-and-22-available-on-riscv-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-21-and-22-available-on-riscv/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-21-and-22-available-on-riscv-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-23-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-23-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-23-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-24-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-24-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-24-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-312-11013-and-1701-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u312-11013-and-1701-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-312-11013-and-1701-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-342-11016-1704-and-1802-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u342-11016-1704-and-1802-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-342-11016-1704-and-1802-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-362-11018-1706-and-1902-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u362-11018-1706-and-1902-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-362-11018-1706-and-1902-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-372-11019-1707-and-2001-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u372-11019-1707-and-2001-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-372-11019-1707-and-2001-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-382-11020-1708-and-2002-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u382-11020-1708-and-2002-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-382-11020-1708-and-2002-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-392-11021-1709-and-2101-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u392-11021-1709-and-2101-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-392-11021-1709-and-2101-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-402-11022-1710-and-2102-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u402-11022-1710-and-2102-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-402-11022-1710-and-2102-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-412-11023-1711-2102-2201-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u412-11023-1711-2102-2201-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-412-11023-1711-2102-2201-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-422-11024-1712-2104-2202-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u422-11024-1712-2104-2202-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-422-11024-1712-2104-2202-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-432-11025-1713-2105-2301-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u432-11025-1713-2105-2301-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-432-11025-1713-2105-2301-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-442-11026-17014-2106-2302-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-8u442-11026-17014-2106-2302-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-8-u-442-11026-17014-2106-2302-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-jdk-24-jep-493-enabled-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-jdk24-JEP493-enabled/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-jdk-24-jep-493-enabled-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-jres-are-back-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-jres-are-back/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-jres-are-back-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-linux-installers-available-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/eclipse-temurin-linux-installers-available/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-eclipse-temurin-linux-installers-available-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-emt-4-j-an-easier-upgrade-for-java-applications-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/emt4j-an-easier-upgrade-for-java-applications/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-emt-4-j-an-easier-upgrade-for-java-applications-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-external-audit-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/external_audit/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-external-audit-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-gpg-signed-releases-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/gpg-signed-releases/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-gpg-signed-releases-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-jlink-to-produce-own-runtime-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/jlink-to-produce-own-runtime/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-jlink-to-produce-own-runtime-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-march-2024-jdk-22-release-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/march-2024-jdk22-release/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-march-2024-jdk-22-release-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-peeling-the-big-onion-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/peeling-the-big-onion/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-peeling-the-big-onion-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-removal-of-centos-7-eclipse-temurin-images-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/removal-of-centos7-eclipse-temurin-images/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-removal-of-centos-7-eclipse-temurin-images-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-reproducible-comparison-builds-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/Reproducible-Comparison-Builds/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-reproducible-comparison-builds-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-secure-software-development-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/secure-software-development/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-secure-software-development-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-slsa-2-temurin-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/slsa2-temurin/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-slsa-2-temurin-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-slsabuild-3-temurin-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/slsabuild3-temurin/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-slsabuild-3-temurin-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-temurin-21-delay-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/temurin21-delay/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-temurin-21-delay-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-using-jlink-in-dockerfiles-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/opt/build/repo/content/blog/using-jlink-in-dockerfiles/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-using-jlink-in-dockerfiles-index-md" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tagPage.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */),
  "slice---src-components-adoptium-news-index-tsx": () => import("./../../../src/components/AdoptiumNews/index.tsx" /* webpackChunkName: "slice---src-components-adoptium-news-index-tsx" */),
  "slice---src-components-author-bio-index-tsx": () => import("./../../../src/components/AuthorBio/index.tsx" /* webpackChunkName: "slice---src-components-author-bio-index-tsx" */),
  "slice---src-components-banner-index-tsx": () => import("./../../../src/components/Banner/index.tsx" /* webpackChunkName: "slice---src-components-banner-index-tsx" */),
  "slice---src-components-footer-index-tsx": () => import("./../../../src/components/Footer/index.tsx" /* webpackChunkName: "slice---src-components-footer-index-tsx" */),
  "slice---src-components-nav-bar-index-tsx": () => import("./../../../src/components/NavBar/index.tsx" /* webpackChunkName: "slice---src-components-nav-bar-index-tsx" */)
}

